@import "./mixin.scss";
@import "./variable.scss";
@import "../css/font-awesome.css";

/*  Reset css */

* {
  box-sizing: border-box;
}

html {
  font-size: 16px !important;
}



html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1.5;
  font-family: "daimler_cs_regular";
  width: 100%;
  overflow: hidden;
  // height: 86vh;
  // background: url(../images/dbdn_bg_1920.jpg);
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote {
  &:before,
  &:after {
    content: "";
    content: none;
  }
}

q {
  &:before,
  &:after {
    content: "";
    content: none;
  }
}

a {
  text-decoration: none;
  color: inherit;

  &:active {
    color: inherit;
  }
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

form,
input {
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
}

img {
  max-width: 100%;
}

*:focus {
  outline: none;
}

.errMsg {
  color: $error !important;
  text-align: left;
  padding-top: 2px;
  font-size: 0.75rem !important;
  margin: 0 !important;
 // display: none;
 //visibility: hidden
}

::-webkit-input-placeholder {
  /* Edge */
  font-size: 13px !important;
  color: #9d9d9d;
  font-weight: normal !important;
}

:-ms-input-placeholder {
  /* Internet Explorer */
  font-size: 13px !important;
  color: #9d9d9d;
  font-weight: normal !important;
}

::placeholder {
  color: #9d9d9d;
  font-weight: normal !important;
  font-size: rem(13);
}

/* Fonts */

@font-face {
  font-family: "daimler_cs_regular";
  src: url("../fonts/DaimlerCS-Regular.eot");
  src: url("../fonts/DaimlerCS-Regular.eot?#iefix") format("embedded-opentype"),
    url("../fonts/DaimlerCS-Regular.woff2") format("woff2"), url("../fonts/DaimlerCS-Regular.woff") format("woff"),
    url("../fonts/DaimlerCS-Regular.ttf") format("truetype"),
    url("../fonts/DaimlerCS-Regular.svg#DaimlerCS-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "daimler_cs_bold";
  src: url("../fonts/DaimlerCS-Bold.eot");
  src: url("../fonts/DaimlerCS-Bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/DaimlerCS-Bold.woff2") format("woff2"), url("../fonts/DaimlerCS-Bold.woff") format("woff"),
    url("../fonts/DaimlerCS-Bold.ttf") format("truetype"),
    url("../fonts/DaimlerCS-Bold.svg#DaimlerCS-Bold") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Glyphicons Halflings";
  src: url("../fonts/glyphicons-halflings-regular.eot") format("embedded-opentype"),
    url("../fonts/glyphicons-halflings-regular.woff") format("woff"),
    url("../fonts/glyphicons-halflings-regular.ttf") format("truetype"),
    url("../fonts/glyphicons-halflings-regular.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

/*Bootstrap Icons*/

.glyphicon {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: "Glyphicons Halflings";
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.glyphicon-edit:before {
  content: "\e065";
}

.glyphicon-trash:before {
  content: "\e020";
}

.glyphicon-menu-down:before {
  content: "\e259";
}

.glyphicon-search:before {
  content: "\e003";
}

.glyphicon-play:before {
  content: "\e072";
}

/*buttons*/

.btn {
  display: inline-block;
  padding: rem(6) rem(12);
  margin-bottom: 0;
  font-size: rem(14);
  line-height: 1.42857143;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.65;
  }
}

.btn.primary-btn {
  background-color: $primary-color;
  border-color: #004355;
  color: #fff;

  &:hover {
    background-color: #04586b;
    border-color: #04586b;
  }
}

.btn.delete-btn {
  background-color: #d9534f;
  border-color: #d43f3a;
  color: #fff;

  &:hover {
    background-color: #c9302c;
    border-color: #ac2925;
  }
}

.edit.btn {
  margin-right: 5px;
}

.edit.btn,
.delete-btn.btn {
  padding: rem(1) rem(5);
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

.btn.save-btn {
  background-color: #5cb85c;
  border-color: #4cae4c;
  color: #fff;

  &:hover {
    background-color: #449d44;
    border-color: #398439;
  }

  .saveIcon::before {
    content: "\e172";
    margin-right: 5px;
  }
}

.btn.clear-btn {
  background-color: #f0ad4e;
  border-color: #eea236;
  color: #fff;

  &:hover {
    background-color: #ec971f;
    border-color: #d58512;
  }
}

.hints {
  text-align: right;
  margin: 0 rem(25) rem(15) 0;
  font-size: rem(14);
  font-weight: 700;
  color: #333;

  &::before {
    content: "*";
    color: $error;
    font-family: "Glyphicons Halflings";
    font-weight: 400;
    font-size: rem(9);
    margin-left: rem(3);
  }
}

/*Dropdown*/

.menu-dropdown {
  display: none;
  position: absolute;
  top: 36px;
  background: #444;
  left: 0;
  z-index: 1;
  min-width: 200px;
  max-height: 250px;
  overflow: auto;

  ul {
    input {
      margin: 10px;
      padding: 10px 0px 10px 10px;
      border-radius: 5px;
      border: none;
    }
  }

  li {
    font-size: rem(14);
    margin: 3px 0px;
    border: none;
    color: #fff;
    font-weight: 700;
    cursor: pointer;

    &:hover {
      background: #fff;
      transition: 0.3s;
      color: #444;

      .dropdown-container {
        color: #000;
      }
    }
  }

  .dropdown-container {
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 15px;
    font-weight: 700;
    color: #fff;
    width: 100%;
  }

  /* Hide the browser's default checkbox */
  .dropdown-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &[type^="radio"] {
      + span {
        border-radius: 50%;
      }
    }
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0px;
    left: 0.5rem;
    height: 16px;
    width: 16px;
    border: solid 2px #fff;
    background-color: #eee;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* On mouse-over, add a grey background color */
  .dropdown-container:hover input ~ .checkmark {
    background-color: #ccc;
  }

  /* When the checkbox is checked, add a blue background */
  .dropdown-container input:checked ~ .checkmark {
    background: rgb(0, 103, 127);
  }
}

/*Checkbox*/

.ChkBox {
  position: relative;
  display: inline-block;

  input[type="checkbox"] {
    visibility: hidden;
    opacity: 0;

    &:checked {
      & + label {
        background: #2489c5;
        border: 1px solid #2489c5;

        &:before {
          opacity: 1;
        }
      }
    }
  }

  label {
    display: inline-block;
    position: absolute;
    left: 0;
    width: 20px;
    height: 20px;
    border: 1px solid #ccc;
    cursor: pointer;
    @include transition(all 0.3s ease);

    &::before {
      content: "";
      position: absolute;
      border: 2px solid #fff;
      width: 9px;
      height: 4px;
      display: inline-block;
      @include transform(rotate(320deg));
      border-width: 0px 0px 2px 2px;
      top: 4px;
      left: 4px;
      opacity: 0;
    }
  }
}

/*Loader*/

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 9;

  .loader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    .loaderWraper {
      max-width: 300px;

      .loaderimg {
        .poof {
          opacity: 0;
          @include animation(poof 4s ease-in infinite);
          vertical-align: bottom;
        }

        .truckImg {
          position: relative;
          @include animations(shake 0.3s ease infinite, rotates 4s linear infinite);
        }

        @keyframes shake {
          0% {
            top: 0px;
          }

          25% {
            top: 1px;
          }

          50% {
            top: 0px;
          }

          75% {
            top: -1px;
          }

          100% {
            top: 0px;
          }
        }

        @keyframes rotates {
          0% {
            transform: rotate(0deg);
          }

          50% {
            transform: rotate(0deg);
          }

          90% {
            transform: rotate(0deg);
          }

          100% {
            transform: rotate(-35deg);
          }
        }

        @keyframes poof {
          0% {
            opacity: 0;
            transform: scale(1, 1);
          }

          70% {
            opacity: 0;
            transform: scale(1, 1);
          }

          76% {
            opacity: 0;
          }

          90% {
            opacity: 0;
          }

          100% {
            opacity: 1;
            transform: scale(1.3, 1.3);
          }
        }
      }

      p {
        color: #f1f1f1;
        letter-spacing: 3.2px;
      }
    }
  }
}

.nodatamsg {
  background: #c0e1e8;
  padding: 10px;
  border-radius: 6px;
  border: solid 1px #a6ccd4;
  margin: 10px;
  display: block;
  text-align: center;
  color: #00677f;
  word-break: break-word;
}

/*Login page*/

.loginPage {
  position: relative;
  background: url(../images/ban-image.jpg) no-repeat;
  background-size: cover;
  height: 111vh;
  margin-top: -80px;

  .loginWrapper {
    @include flexbox();
    @include justify-content(center);
    @include flex-direction(column);
    @include align-items(center);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: white;
    width: 100%;
    max-width: 400px;
    text-align: center;
    // background-image: -webkit-linear-gradient(77deg, rgba(0, 50, 70, 0.6) 0%, rgba(23, 49, 56, 0.4) 100%);
    // background-image: -o-linear-gradient(77deg, rgba(0, 50, 70, 0.6) 0%, rgba(23, 49, 56, 0.4) 100%);
    // background-image: linear-gradient(373deg, rgba(0, 50, 70, 0.6) 0%, rgba(23, 49, 56, 0.4) 100%);

    h1 {
      margin-top: rem(20);
      margin-bottom: rem(10);

      img {
        width: 100%;
        max-width: 300px;
       
      }
    }

    .loginContainer,
    .forgotpwdContainer {
      width: 70%;
      margin: 0 auto;
      padding: 0 rem(10);

      h2 {
        margin-top: rem(20);
        margin-bottom: rem(10);
        font-weight: 300;
        color: #fff;
        font-size: rem(24);
        padding: rem(6) 0;
        line-height: 1.1;
      }

      p {
        line-height: normal;
        color: #fff;
        font-size: rem(14);
        margin: 0 0 10px;
      }

      .form-group {
        .inputField {
          width: 100%;
          padding: rem(8) rem(12);
          font-size: rem(14);
          border: 1px solid #73a3cb;
          border-radius: 2rem;
          font-weight: normal;

          &:focus {
            @include box-shadow(none);
          }

          &.required {
            & ~ .errMsg {
              font-weight: normal;
            }
          }
        }
      }

      button {
        border-radius: 0;
        border-color: transparent;
      }

      a {
        display: block;
        font-size: rem(14.667);
        padding: rem(5) 0;
        color: #fff;
        margin-top: rem(15);
        cursor: pointer;
      }
    }
  }
}

/*Side menu*/

.sideMenu {
  // display: inline-block;
  // position: absolute;
  // top: 0px;
  // bottom: 0;
  // left: 10px;
  // background: #000;
  // border-radius: 5px 0 0 5px;
  // overflow: hidden;
  // min-height: 400px;
  ////////////////////////////
  display: inline-block;
  /* top: 0px; */
  position: absolute;
  top: 4.62rem;
  bottom: 0;
  /* left: 10px; */
  background: #000;
  border-radius: 0px 0 0 5px;
  overflow: hidden;
  min-height: 400px;
  // display: inline-block;
  // /* top: 0px; */
  // position: absolute;
  // /* top: 4.62rem; */
  // bottom: 8.6rem;
  // /* left: 10px; */
  // background: #000;
  // border-radius: 0px 0 0 0px;
  // overflow: hidden;
  // min-height: 32.3rem;

  .MenuToggle {
    border-left: 3px solid transparent;
    line-height: 40px;
    height: 40px;
    cursor: pointer;

    svg {
      height: 20px;
      width: 20px;
      margin-left: 15px;
      vertical-align: middle;

      path {
        fill: #fff;
      }
    }

    &:hover {
      background: rgba(255, 255, 255, 0.2);
      border-left: 3px solid $B-color;
    }

    &.close + ul {
      width: 50px;
    }
  }

  ul {
    width: 200px;
    overflow: hidden;
    @include transition(0.3s all ease);

    li {
      border-left: 3px solid transparent;
      white-space: nowrap;
      margin-bottom: 2px;

      a {
        display: block;
        font-size: rem(14.667);
        padding-left: rem(5px);
        color: #fff;

        span {
          display: inline-block;
          margin-right: rem(15px);
          width: 35px;
          height: 40px;

          svg {
            vertical-align: middle;
            width: 100%;
            height: 100%;
          }
        }
      }

      &:hover {
        background: rgba(255, 255, 255, 0.2);
        border-left: 3px solid $B-color;
      }

      &.active {
        background: $B-color;
      }
    }
  }
  ~ .panel {
    margin: 0rem 0rem 35px 12.5rem;
  }
  &.Menuclose ~ .panel {
    margin: 0rem 0rem 35px 3.2rem;
    overflow-y: auto;
    height: calc(100vh - 100px);
  }
}

/* Inner Pages*/

.container {
  // position: relative;
  // margin-top: 80px;

  .wrapper {
    // margin: rem(95) rem(10) rem(35);
    // margin-top:78px;
    padding-top: 0.3rem;

    h2 {
      color: #e6e6e6;
      font-size: rem(36);
      margin: 0 rem(27) rem(3);
      padding-top: rem(6);

      .greeting {
        font-size: rem(25);
        float: right;
      }
    }

    .dashboard {
      @include flexbox();
      @include flex-wrap(wrap);
      width: 100%;

      .menu-list {
        padding: 0 rem(12) rem(20);
        width: 16.66666667%;

        .cards {
          background-color: $primary-color;
          padding-top: rem(16);
          box-shadow: 0 1px 0 #c9cece, 0 2px 0 #bcc2c1, 0 3px 0 #aeb6b5, 0 4px 0 #a3acab, 0 5px 0 #9ea7a6,
            0 6px 0 #99a2a1, 0 7px 0 #96a09f, 0 8px 0 #939e9d, 0 0 5px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.2),
            0 3px 5px rgba(0, 0, 0, 0.2), 0 5px 10px rgba(0, 0, 0, 0.2), 0 10px 10px rgba(0, 0, 0, 0.2),
            0 20px 20px rgba(0, 0, 0, 0.3);
          cursor: pointer;

          .cardImage {
            text-align: center;
            // height: 163px;

            svg {
              width: 90%;
              // height: 100%;
            }
          }

          &:hover {
            background-color: #fff;

            & .cardImage {
              svg {
                rect,
                path {
                  fill: $primary-color;
                }

                line {
                  stroke: #fff !important;

                  & ~ g path {
                    fill: #fff;
                  }
                }
              }
            }

            & .cardIndex {
              background-color: $primary-color;
            }
          }

          .cardIndex {
            color: #fff;
            font-size: rem(19);
            text-align: center;
            background-color: rgba(0, 0, 0, 0.35);
            padding: rem(10) 0;
          }
        }
      }
    }
  }

  .align_center {
    text-align: center;

    .btn {
      margin: rem(21) 0 0;
      padding: rem(4) rem(18);
      font-size: rem(19);
      border: 2px solid #00677f;
      font-family: "daimler_cs_regular";
    }
  }
}

/*Profile Page*/

.panel {
  background: #fff;
  @include border-radius(0px 5px 5px 0px);
  padding: rem(10) rem(12) rem(20);
  @include transition(0.3s all ease);
  min-height: 400px;
  overflow-y: auto;
  height: calc(100vh - 100px);
  .search {
    margin: rem(5) 0;

    .search-bar {
      @include justify-content(flex-end);

      .inputField {
        max-width: 378px;
      }
    }
  }

  .panel_container {
    @include border-radius(4px);
    border: 1px solid #ddd;
    padding-bottom: rem(25);

    .panelHeading {
      @include flexbox;
      @include justify-content(space-between);
      @include align-items(center);
      background: $primary-color;
      padding: rem(4) rem(15);
      min-height: 38px;

      h2 {
        font-size: rem(14.667);
        color: #fff;
        margin: 0;
      }

      .btn {
        position: relative;
        border-radius: 0 !important;
        @include box-shadow(0 8px 6px -6px #000);
        border-color: #004355;
        margin: 0 5px;
      }
    }

    .tableWrapper {
      // padding: rem(15) rem(6);
    }
  }
}

.tooltipbtn {
  display: inline-block;
  position: relative;

  .tooltip {
    position: absolute;
    display: inline-block;
    font-size: 12px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: 900;
    line-height: 1.42857143;
    padding: rem(5) rem(7);
    top: 2px;
    right: 50px;
    background: #000;
    opacity: 0;
    visibility: hidden;
    border-radius: 5px;
    @include transition(0.3s all ease);
    color: #fff;
    white-space: nowrap;

    &::before {
      content: "";
      width: 10px;
      height: 10px;
      background: #000;
      position: absolute;
      right: -1px;
      @include transform(rotate(45deg) translateY(-50%));
      top: 50%;
    }
  }

  .btn {
    &:hover + .tooltip {
      opacity: 1;
      visibility: visible;
    }
  }
}

/* Header */

header {
  @include flexbox;
  // position: fixed;
  top: 0;
  left: 0;
  right: 0;
  @include justify-content(space-between);
  @include flex-wrap(wrap);
  background-color: #f1f8fd;
 
  z-index: 3 !important;

  .navbar-header {
    @include flexbox();

    .logo {
      height: 72px;

      a {
        display: inline-block;
        padding: 5px 10px 0;

        img {
          width: 240px;
        }
      }
    }

    .nav-button {
      display: none;
      height: 34px;
      width: 44px;
      padding: 9px 10px;
      background: transparent;
      border: 1px solid #333;
      border-radius: 4px;
      cursor: pointer;
      margin: 20px 15px 0 0;

      .icon-bar {
        display: block;
        height: 2px;
        border-radius: 1px;
        background: #fff;
        margin-top: 4px;

        &:first-child {
          margin-top: 0px;
        }
      }

      &:hover {
        background: #333;
      }
    }
  }

  .navBar {
    margin: 0 0 0 auto;

    ul {
      @include flexbox();
      height: 100%;
      text-align: center;

      li {
        // border-left: 1px solid rgba(255, 255, 255, 0.1);
        width: 8vw;
        a {
        
          // display: inline-block;
          // color: #fff;
          // font-size: rem(16);
          // padding: rem(10) rem(25);
          // text-align: center;
          // font-size: 0.8rem;

          //
          display: inline-block;
          color: #fff;
          text-align: center;
          font-size: 0.7rem;
          // div {
          //     display: block;
          //     height: 30px;
          //     margin: 0 auto;
          // }
        }

        &:hover,
        &.active {
          background-color: rgba(255, 255, 255, 0.2);
        }
      }
    }
  }
}

.home_icon {
  height: 53px;
  width: 60px;
  zoom: 0.8;

}

.fleet_icon {
  height: 53px;
  width: 60px;
  zoom: 0.8;
 // background: url(/static/media/icon_set.fab57150.png) -80px 0px;
}

.driving_analysis_icon {
  height: 53px;
  width: 80px;
  zoom: 0.8;
//  background: url(/static/media/icon_set.fab57150.png) -161px 0px;
}

.truck_utilization_icon {
  height: 53px;
  width: 83px;
  zoom: 0.8;
 // background: url(/static/media/icon_set.fab57150.png) -262px 0px;
}

.daily_trip_analysis_icon {
  height: 53px;
  width: 83px;
  zoom: 0.8;
 // background: url(/static/media/icon_set.fab57150.png) 301px 0;
}

.dtc_icon {
  height: 53px;
  width: 60px;
  zoom: 0.8;
 // background: url(/static/media/icon_set.fab57150.png) 171px 0;
}

.reports_icon {
  height: 53px;
  width: 60px;
  zoom: 0.8;

}

.profiles_icon {
  height: 53px;
  width: 60px;
  zoom: 0.8;
//  background: url(../images/signout-menu.png) 174px 0;
}

.settings_icon {
  height: 53px;
  width: 60px;
  zoom: 0.8;
 // background: url(../images/signout-menu.png) -15px 0;
}

.signout_icon {
  height: 53px;
  width: 60px;
  zoom: 0.8;
 // background: url(../images/signout-menu.png) 76px 0;
}

#nested {
  @include flexbox();
  height: 100%;
  flex-direction: column;
}

.nav_anchor {
  padding: 0rem 4rem 1rem 0rem !important;
}

/* Footer */

footer {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  @include flexbox();
  @include justify-content(space-between);
  @include flex-wrap(wrap);
  padding: rem(5) rem(18);
  border-bottom: 2px solid $B-color;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 2 !important;
  p {
    font-size: rem(14.667);
    color: #fff;

    svg {
      margin-right: 3px;
    }
  }
}

/*popup*/

.popup_overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: auto;
  padding: 0 10px;
  z-index: 9;
  background: rgba(0, 0, 0, 0.6);

  .playBackPop{
    max-width: 900px !important;
    margin: 10rem auto !important;
  }

  .pop-up {
    background: #fff;
    width: 100%;
    max-width: 600px;
    margin: 30px auto;
    left: 0;
    right: 0;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    border-radius: 6px;

    .popupHeader {
      position: relative;
      @include flexbox();
      @include justify-content(space-between);
      @include align-items(center);
      background: $primary-color;
      padding: rem(11) rem(15);
      border: 0;

      h3 {
        font-size: rem(18);
        font-weight: 700;
        color: #fff;
        cursor: default;
        line-height: 1.42857143;
      }

      .popupclose {
        svg {
          cursor: pointer;

          path {
            fill: #fff;
          }
        }
      }
    }

    form {
      @include flexbox();
      @include flex-wrap(wrap);
      padding: rem(31) rem(15) 0;

      .leftwrapper,
      .rightwrapper {
        padding: 0 10px;
        width: 50%;
      }

      .footerBtn {
        margin: rem(20) 0 rem(10);
        width: 100%;
        text-align: center;

        button {
          margin-right: 10px;
        }
      }

      .errMsg {
        font-size: 0.75rem;
        font-weight: 700;
      }
    }

    .pop_footer {
      background: $primary-color;
      padding: rem(15);
    }

    &.threecol {
      max-width: 700px;

      section {
        form {
          .leftwrapper,
          .rightwrapper,
          .midwrapper {
            width: 33.3%;
            padding: 0 10px;
          }
        }
      }
    }
  }
}

/*Input Fields*/

.form-group {
  margin-bottom: rem(15);

  label {
    position: relative;
    display: block;
    font-size: rem(13);
    font-weight: 700;
    color: #333;
    margin-bottom: 5px;

    &.compulsory::after {
      content: "*";
      color: $error;
      font-family: "Glyphicons Halflings";
      font-weight: 400;
      font-size: rem(9);
      margin-left: rem(3);
    }
  }

  .inputField,
  .dd__selectControl {
    width: 100%;
    height: 40px;
    padding: rem(6) rem(12);
    font-size: rem(14) !important;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-weight: 600;
    @include box-shadow(inset 0 1px 1px rgba(0, 0, 0, 0.075));
    @include transitions(border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s);

    &:focus {
      border-color: #66afe9 !important;
      outline: 0;
      -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
    }

    &[readonly] {
      background-color: #eee;
      cursor: not-allowed;

      &:focus {
        border-color: #ccc !important;
        @include box-shadow(inset 0 1px 1px rgba(0, 0, 0, 0.075));
      }
    }

    &.required {
      & ~ .errMsg {
      //  display: block;
        font-size: rem(12);
        font-weight: 700;
      }
    }
  }

  .mobInput {
    @include flexbox;

    .pincode {
      width: 80px;
      margin-right: 2px;
    }

    &.required ~ .errMsg {
      display: block;
    }
  }

  .dd__opened .dd__list {
    border-color: #66afe9;
    z-index: 9;

    .ReactVirtualized__Grid__innerScrollContainer {
      div {
        div {
          cursor: pointer;
          padding: rem(10);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          &:hover {
            background: #66afe9;
          }
        }
      }
    }
  }

  &.search-bar {
    @include flexbox;
    margin: 0;

    .search-btn {
      display: inline-block;
      color: #1a237e;
      background-color: #c5cae9;
      cursor: pointer;
      padding: rem(8) rem(11);
      font-size: 14px;
      line-height: 1;
      border: 1px solid #ccc;
      border-radius: 4px 0px 0px 4px;
    }

    .inputField {
      border-radius: 0px 4px 4px 0px;
      font-weight: normal;

      &:focus {
        border-color: #ccc !important;
      }
    }
  }

  select {
    width: 100%;
    height: 34px;
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem !important;
    color: #555;
    font-family: "daimler_cs_regular";
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-weight: 600;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);

    &:focus {
      border-color: #66afe9 !important;
      outline: 0;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
    }
  }
}

/*Reports*/

.reports,
.trip-analysis {
  height: calc(100vh - 120px);
  h2 {
    font-size: rem(24);
    color: #ef6d3b;
    margin: rem(20) 0 rem(10);
    line-height: 1.1;
  }

  .reportFilters {
    padding: 12px;
    position: relative;
    background-color: #f7f7f9;
    border: 1px solid #e1e1e8;
    border-radius: 4px;
    margin-bottom: rem(10);

    .form-group {
      margin-bottom: 0;
      display: inline-block;
      padding: rem(5) rem(10);

      label {
        display: inline;
        font-size: rem(13);
        margin: 0 rem(10) 0 0;
        font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
      }

      .dropdown {
        display: inline-block;
        position: relative;
        width: 200px;

        button {
          width: 100%;
          padding: 6px 12px;
          font-size: 14px;
          line-height: 1.42857143;
          color: #555;
          background-color: #fff;
          background-image: none;
          border: 1px solid #ccc;
          border-radius: 4px;
          text-align: left;
          cursor: pointer;

          i {
            float: right;
            color: #000;
            font-size: 11px;
            top: 4px;
          }

          &:hover {
            color: #333;
            background-color: #e6e6e6;
            border-color: #adadad;
          }
        }

        &.open {
          button {
            @include box-shadow(inset 0 3px 5px rgba(0, 0, 0, 0.125));
            color: #333;
            background-color: #e6e6e6;
            border-color: #adadad;
          }

          .menu-dropdown {
            display: block;
          }
        }
      }

      .btn {
        font-weight: 700;
        margin: 0 rem(5);

        i {
          margin-right: 4px;
        }
      }

      img {
        height: 40px;
        margin: 0 rem(5);
        vertical-align: middle;
        cursor: pointer;
      }

      .date-picker {
        display: inline-block;
        margin-bottom: 5px;

        &:first-child {
          margin-right: 20px;
        }
      }

      select {
        width: auto;
        font-weight: 400;
      }
    }
  }

  .panel_container {
    @include box-shadow(0 3px 5px #e2e2e2);
    border: 0;

    .tableWrapper {
      // padding: rem(35) rem(26);

      .playback {
        border-radius: 50%;
        background-color: #337ab7;
        padding: rem(10);
        font-size: rem(14.667);
        color: #333;
        cursor: pointer;
        border: none;
        outline: none;
      }

      .loadMore {
        text-align: center;
        margin-top: 10px;

        .btn {
          border-radius: 0;
          @include box-shadow(0 8px 6px -6px #000);
          font-family: "daimler_cs_regular";
        }
      }
    }
  }

  .navTab {
    ul {
      li {
        display: inline-block;
        padding: rem(10) rem(15);
        margin-right: 2px;
        color: #337ab7;
        cursor: pointer;
        font-size: rem(14.667);
        @include transition(0.3s all ease);

        &.active {
          background: #a70e13 !important;
          color: #fff;
        }

        &:hover {
          background-color: #eee;
        }
      }
    }
  }
}

.trip-analysis {
  .panel_container {
    .tableWrapper {
      .search-bar {
        max-width: 42%;
        margin-bottom: 15px;
      }
    }
  }
}

.mapPopup {
  .close-popup {
    color: #fff;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    background-color: rgb(45, 187, 235);
    border-radius: 61%;
    width: 2rem;
    /* margin: auto; */
    /* align-content: center; */
    padding: 0.3rem 0.7rem;
    /* padding-left: 0.7rem; */
    height: 2rem;
  }

  .action-icons {
    text-align: right;
    position: absolute;
    z-index: 1;

    i {
      background-color: #00677f;
      border: solid 1px #004355;
      padding: 10px 14px;
      border-radius: 3px;
      margin: 10px;
      color: #fff;
      cursor: pointer;

      &:hover {
        background-color: #004355;
        border-color: #00677f;
      }
    }
  }

  .mapbody {
    min-height: 350px;
    padding: 15px;
    width: 80%;
  }
}

//Progress Bar
.progress {
  background: #f5f5f5;
  border-radius: 4px;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  height: 20px;
  overflow: hidden;

  .progress-bar {
    text-align: center;
    background-color: #5bc0de;
    background-image: linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    );
    background-size: 40px 40px;
    height: 100%;

    span {
      font-size: 12px;
      color: #3c3c3c;
    }
  }
}

/*Responsive*/

@media only screen and (max-width: 1200px) {
  .panel {
    .search {
      .search-bar {
        .inputField {
          max-width: 100%;
        }
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .container {
    .wrapper {
      .dashboard {
        .menu-list {
          width: 25%;
        }
      }
    }

    .popup_overlay {
      .pop-up {
        section {
          form {
            .leftwrapper,
            .rightwrapper,
            .midwrapper {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 820px) {
  .sideMenu {
    .MenuToggle {
      display: none;
    }

    ul {
      width: 50px;
    }
  }

  header {
    .navbar-header {
      width: 100%;
      @include justify-content(space-between);

      .nav-button {
        display: block;
      }
    }

    .navBar {
      position: absolute;
      top: 100%;
      width: 100%;
      background: #222;
      -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
      box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
      border-bottom: 2px solid $B-color;
      overflow: auto;
      max-height: 0;
      @include transition(0.3s all ease);

      ul {
        @include flex-direction(column);
        border-top: 1px solid #101010;

        li {
          border-top: 1px solid rgba(255, 255, 255, 0.05);

          a {
            @include flexbox();
            @include align-items(center);
            padding: 0 rem(25);

            img {
              display: inline-block;
              margin: 10px;
            }
          }
        }
      }

      &.open {
        max-height: 340px;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .container {
    .wrapper {
      .dashboard {
        .menu-list {
          width: 50%;
        }
      }
    }
  }
}

@media only screen and (max-width: 640px) {
  .loginPage {
    .loginWrapper {
      background-image: none;
      max-width: 100%;

      .loginContainer,
      .forgotpwdContainer {
        width: 90%;
        background-image: -webkit-linear-gradient(77deg, rgba(0, 50, 70, 0.6) 0%, rgba(23, 49, 56, 0.4) 100%);
        background-image: -o-linear-gradient(77deg, rgba(0, 50, 70, 0.6) 0%, rgba(23, 49, 56, 0.4) 100%);
        background-image: linear-gradient(373deg, rgba(0, 50, 70, 0.6) 0%, rgba(23, 49, 56, 0.4) 100%);
        border-radius: 8px;
        padding: rem(20);
        margin: rem(20) 0 rem(40);

        .inputField {
          border-radius: 4px !important;
        }
      }
    }
  }

  .container {
    .wrapper {
      // margin: rem(80) rem(10) rem(60);
    }
  }

  .panel {
    //margin: 0px 10px 55px 60px !important;
  }

  .reports,
  .trip-analysis {
    .panel_container {
      .navTab {
        ul {
          li {
            width: 32.5%;
          }
        }
      }
    }

    .reportFilters {
      .form-group {
        width: 100%;

        label {
          display: block;
        }

        .dropdown,
        select {
          width: 100%;
        }

        .date-picker {
          display: block;
          margin-right: 0 !important;

          .ant-calendar-picker {
            width: 100%;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 460px) {
  .panel > h2 {
    margin-top: rem(5);
    font-size: rem(22);
  }

  .reports {
    .reportFilters {
      .form-group {
        padding: rem(5) 0;
      }
    }

    .panel_container {
      .navTab {
        ul {
          li {
            width: 49%;
          }
        }
      }
    }
  }

  .trip-analysis {
    .reportFilters {
      .form-group {
        padding: rem(5) 0;
      }
    }

    .panel_container {
      .tableWrapper {
        .search-bar {
          max-width: 100%;
        }
      }
    }
  }

  .container {
    .popup_overlay {
      .pop-up section {
        form {
          .midwrapper,
          .leftwrapper,
          .rightwrapper {
            padding: 0;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 360px) {
  .container {
    .wrapper {
      //margin: rem(80) rem(10) rem(80);

      .dashboard {
        .menu-list {
          .cards {
            .cardImage {
              height: 90px;
            }

            .cardIndex {
              font-size: rem(15);
            }
          }
        }
      }
    }
  }

  .panel {
    margin: 0px 10px 80px 60px !important;
    padding: rem(5);

    .panel_container {
      .tableWrapper {
        padding: 5px 0;
      }
    }
  }
}
.myfleet-wrapper{
.gm-style .gm-style-iw-t::after {
display: none;
}
}

.gm-style-iw-d {
  max-height: unset !important;
}

#region_info {
  position: relative;
  padding: 10px 10px 15px;
}
.myfleet-wrapper{
.gm-style .gm-style-iw-t #region_info .temp:after {
  background: #fff;
  box-shadow: -2px 2px 2px 0 rgba(178, 178, 178, 0.4);
  content: "";
  height: 15px;
  position: absolute;
  bottom: 0;
  transform: translate(-50%, -50%) rotate(-45deg);
  width: 15px;
  left: 50%;
}

.gm-style .gm-style-iw-t .maps-popup .tempo:after {
  background: #444;
  box-shadow: -2px 2px 2px 0 rgba(178, 178, 178, 0.4);
  content: "";
  height: 15px;
  left: 0;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
  width: 15px;
}
}

.temp {
  padding: 10px 25px 10px 10px;
  background: #fff;
  box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
}

.tempo {
  padding: 10px 10px 0px 10px;
  color: #fff;
  background: #444;
  border-radius: 8px;
  font-size: 12px;
  max-width: 350px;
}

.maps-popup {
  position: relative;
}

.tab-list {
  border-bottom: 1px solid #ccc;
  // padding-left: 0;
  // padding-left: 0.3rem;
  // padding-left: 1rem;
  // padding-right: 1rem;
  // border-bottom: 1px solid #ccc;
  margin-left: 1rem;
  margin-right: 1rem;
}

.tabs {
  .reportFilters {
    margin: 0;
    padding: 0;
    background: none;
    border: none;
  }

  .tab-list-item {
    display: inline-block;
    list-style: none;
    margin-bottom: -1px;
    padding: 0.5rem 0.75rem;
    cursor: pointer;
    background: #eaeaea;
    color: #d3d3d3;
  }

  .tab-list-active {
    background-color: white;
    border: none;
    color: #3c3c3c;
    // box-shadow: 1px 1px 1px 1px #eaeaea;
  }

  .tab-content {
    // padding-top: .7rem;
    overflow-y: auto;
    position: relative;
    height: calc(100vh);
  }

  .tab-list {
    display: flex;
    // position: absolute;
  }

  .dropdown-div {
    display: flex;
    // flex-direction: row-reverse;
    // flex-grow: 5;
    padding-left: 75rem;

    .custom-tab-item {
      display: flex;
      flex-direction: row-reverse;
    }

    .dropdown {
      width: auto !important;
    }

    .customButton {
      width: 120px !important;
    }

    .glyphicon-menu-down {
      margin-left: 0.2rem;
    }

    .menu-dropdown {
      min-width: 120px !important;
    }
  }
}

.custom-menu-list {
  flex-grow: 1;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  // height: 300px;
  // margin: 10px;
  margin: 0.3rem;
  width: 21.3rem;
  height: fit-content;
  // height: 300px;
  // display: flex;
  // width: auto !important;
}

.highcharts-container {
  //width: 30rem !important;
  // height: 300px !important;
  //   height:250px !important;
  //   width: 24rem !important;
  //   margin-right: 0 !important;
  height: 100%;
  width: 100%;
}

// .highcharts-root {
//     width: 24rem !important;
//     height: 250px;
//     margin-right: 0 !important;
// }

// .highcharts-figure, .highcharts-data-table table {
//     min-width: 400px;
//     max-width: 800px;
//     margin: 1em auto;
// }

.custom-dashboard-left {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 10rem;
  // padding: 0.4rem;
  //padding: 0rem 0rem 0.4rem;
  // padding-left: 1.2rem;
  align-content: flex-start;
  color: black;
  // padding-top: 1rem;
  flex: 3;
}

.custom-dashboard-right {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 10rem;
  // padding: 0.4rem;
  //padding: 0rem 0rem 0.4rem;
  // padding-left: 1.2rem;
  align-content: flex-start;
  color: black;
  // padding-top: 1rem;
  flex: 1;
  margin: 0 0 0.6rem;
}

.custom-dashboard > div {
  // flex: 0 33%;
}

.custom-cards {
  //    width: 22rem;
  //    height: 300px;
  //    margin: 0px !important;
  //    box-shadow: 1rem 0.2rem 2rem 0.01rem #c6c1c1;
  // width: 22rem;

  // width: 22rem;
  // width: 30rem;
  // width: 22.3rem;
  // height: 300px;
  margin: 0px !important;
  // box-shadow: 0.5rem 1rem 1rem 0.3rem #e4e4e48c;
  // box-shadow:0.5rem 1rem 0.5rem 0.3rem #e4e4e48
}

.custom-menu-list > p {
  // box-shadow: 0.5rem 1rem 1rem 0.3rem #e4e4e48c;
  // box-shadow: 0.5rem 1rem 1rem 0.3rem #e4e4e48c;
  display: inline-block;
  width: 100%;
  text-align: center;
  // background: #b3b3b330;
  // background: #d4d4d430;
  background: #ebe6e6a3;
  height: 1.8vw;
  margin-bottom: 0.6rem;
}

.arrow-closed,
.arrow-open {
  border: solid #999;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 4px;
  position: absolute;
  right: 10px;
}

.arrow-closed {
  top: 10px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.arrow-open {
  top: 14px;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.cdropdown {
  position: relative;
  display: inline-block;
  padding-left: 0;
}

.cdropdown-content {
  display: none;
  position: absolute;
  // background-color: #f9f9f9;
  background-color: #222020;
  // min-width: 160px;
  // min-width: 100px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
  right: 0.01rem;
  width: 4.8rem;
  top: 4.5rem;
}

.cdropdown:hover .cdropdown-content {
  display: block;
  padding: 0.1rem 0.1rem 0.1rem 0rem;
  // margin-top: 1rem;
}

//    ul li a{

//   }

//   .settings-menu {
//       ul {
//             li {
//                 a {
//                     padding: 0.1rem 0.1rem 0.1rem 0.3rem;
//                 }
//             }
//       }
//     }

#settings-menu {
  display: inline-block !important;
}

#settings-menu > li {
  width: 5rem !important;
}
#settings-menu li a {
  // padding: 0.1rem 0.1rem 0.1rem 0.3rem !important;
  // padding: 0.1rem 1.2rem 0.1rem 0.3rem !important;
  // padding: 0 !important;
}

.menu-css {
  width: 2.5rem !important;
  // background: red;
}

.n-menu {
  width: 11rem !important;
}
/* Header CSS starts here */
.topnav {
  overflow: hidden;
  background-color: #333;
}

.topnav a {
  float: left;
  display: block;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

.topnav a:hover {
  background-color: #ddd;
  color: black;
}

.topnav a.active {
  background-color: #ddd;
  color: black;
}

.topnav .icon {
  display: none;
}

@media screen and (max-width: 768px) {
  .topnav ul {
    display: inline-flex;
  }
}

@media screen and (max-width: 768px) {
  .topnav a:not(:first-child) {
    display: none;
  }
  .topnav a.icon {
    float: right;
    display: block;
  }
  .tableWrapperAnalysys table thead tr th:last-child{
    min-width: 30px !important;
  }
  .tooltip .tooltiptext{
    margin-left: -91px !important;
    width: 94px !important;
  }
  .tooltip .tooltiptext::before{
    margin-left: 33px !important;
  }
  .tooltip .tooltiptext::after{
    margin-left: 34px !important;
  }
}

@media screen and (max-width: 768px) {
  .topnav.responsive {
    position: relative;
    overflow: auto;
  }
  .topnav.responsive .icon {
    position: absolute;
    right: 0;
    top: 0;
  }
  .topnav.responsive a {
    float: none;
    display: block;
    text-align: left;
  }
}
.lastMenu {
  width: 5vw !important;
}
@media all and (width: 1024px) and (height: 768px) {
  .lastMenu {
    width: 7vw !important;
  }
  .navBar {
    ul {
      li {
        width: 10vw !important;
      }
    }
  }
}

/* Header CSS ends here*/

.dash-dropdown {
  display: inline-block !important;
  right: 1rem !important;
  position: absolute !important;
}

.dash-dropdown-2 {
  display: inline-block !important;
  right: 11rem !important;
  position: absolute !important;
}

//sidemenu breaks: 1496 : 4.6rem
// 820 px : 4.5
//normal : 5 rem

// height: 67vh;
//overflow-y: scroll

// @media screen and (min-width: 1433px){
//     #right-scrollable {
//         height: 59vh;
//         overflow-y: scroll ;
//     }
// }

@media screen and (min-width: 1433px) and (max-height: 728px) {
  #right-scrollable {
    height: 69vh;
    overflow-y: scroll;
  }
}

@media screen and (min-height: 729px) and (max-height: 740px) {
  #right-scrollable {
    height: 69vh;
    overflow-y: scroll;
  }
}

@media screen and (min-height: 740px) {
  #right-scrollable {
    height: 67vh;
    overflow-y: scroll;
  }
}

@media screen and (min-height: 779px) {
  #right-scrollable {
    height: 59.9vh;
    overflow-y: scroll;
  }
}

@media screen and (max-width: 1432px) {
  #right-scrollable {
    height: 100vh;
    overflow-y: scroll;
  }
}

// @media screen and (min-height:856px){
//     #right-scrollable {
//         height: 55.5vh;
//         overflow-y: scroll ;
//     }
// }

// @media screen and (max-height:838px){
    
// }


// Trip creation page-------------------------------------------------------------------------------------

.graph-container{
    display:flex;
    width: 100%;
    margin-bottom: 0.625rem;
}

.graph-sub-container{
   // border: solid gray;
    width:20%;
   margin-right: 1rem;
   padding-top: 1.5rem;
   border-right:solid grey;
   border-bottom:solid grey;
   border-top:solid grey;

}

.chart-flex{
    display: flex;
    justify-content: space-between;
    padding-bottom: 0.5rem;
}

.progress-bar{
    margin-bottom: 1rem;
}

.graph-sub-container1 > p{

    margin-bottom: 0.95rem;
}

.graph-sub-container1{
    width:12%;
    padding-top: 1.5rem;
    border-left:solid grey;
    border-bottom:solid grey;
    border-top:solid grey;
}

.react-sweet-progress-line{
    background-color: white !important;

}
.react-sweet-progress-line-inner-status-active:before{
    animation: none !important;
}
.graph-sub-container2{
    width:5%;
    padding-top: 1rem;
}

.apexcharts-legend-marker{
    background:none !important;
}

.graph-sub-container3{
    width: 29.9%;
}

.graph-sub-container-radial{
    width:20%;
    margin-right: 1rem;
    zoom: 0.8;
    border:solid grey;

}

.bar-graph{
    margin-bottom: 1rem;
    width: 0.2rem;
  
    // display: flex;
   align-items: center;
    // justify-content: center;
    // width: 100%;
}

.progress-fill{
     border-radius: 1rem;
    margin-top: 7px;
    height: 8px;
    margin-bottom: 1.5rem;
   
}

.bar-graph-padding{
   // padding-bottom: 0.5rem;
}

// dummy----------------------------

.each-width{
    width:21.5%;
    border: solid 0.1rem lightgrey;
    border-radius: 1rem;
   padding: 1rem;
   padding-bottom: 0rem;
  // height: fit-content;

}

.d-flex{
    display:flex;
}
.justify-content-between{
  justify-content: space-between;
}

.loader-img-can{
  padding: 5rem;
  padding-right: 27rem;
  text-align: center;
}

.loader-text-can{
  color: #2881cc;
  font-family: "daimler_cs_regular";
  font-size: 1.4rem;
}

.text-width{
    width: 100%;
    margin-right:0.1rem;
    font-size: 1rem;
}
.progress-fill{
// width: 59%;
}

.temp1{
    display: flex;
    width: 100%;
}
.sub-text{
    display: flex;
   justify-content: flex-end;
   padding-left: 0.5rem;
}

.graph-zoom{
    zoom:0.7;
}

.each-width1{
    width:34%;
// padding: 1rem;
height:10%;
    border: solid 0.1rem lightgrey;
    // border-radius: 1rem;
    border-radius: 1rem;
}

.graph-heading{
    margin-bottom: 0.8rem;
    font-size: 1rem;
    line-height: 1.37rem;
    font-weight: bold;
}

.radialNumber{
    font-weight: bold;
}

.padding-radial{
    padding-right:0.5rem;
    padding-left:0.5rem;
}

.panelHeading-trip{
    align-items: center;
    background: #00677f;
    // padding: 0.25rem 0.9375rem;
    min-height: 38px;
}

.trip-tab>p{
    padding-right: 1.5rem;
    padding: 0.7rem;
    color: white;
}


.trip-tab{
    min-height: 38px;
    cursor: pointer;
}

.radial-heading{
    font-size: 1rem;
    width: 50%;
}

.create-trip-element{
   
    width: 34%;
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%,-50%);
}

.pb-1{
    padding-bottom: 1rem;
}
.create-btn{
    border: none;
    padding:0.7rem;
    color: white;
  background:#00677f;
    border-radius: 4px;
        
}

.trip-description{
    text-align: center;
}

.trip-description > p:first-of-type{
font-weight: bold;
}



// }

//----------terms ad privacy policy styles-----------------------------------------------

//desktop and ipad
@media (min-width: 768px) {
  .footer-pop-container {
    display: flex;
    justify-content: space-around;
  }

  .footer-pop-left {
    width: 45%;
   
  }

  .footer-pop-logo {
    background: black;
    margin-bottom: 1.25rem;
  }

  .footer-pop-right {
    width: 45%;
  
  }

  .footer-left {
    display: flex;
    p:first-child {
      padding-right: 10px;
    }
  }
}

.backdrop{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-x: hidden;
  background-color: rgba(0,0,0,0.3);
  padding: 3.125rem;
  z-index: 50;
}

.closeButton{
  position: absolute;
    top: 0.875rem;
    right: 0.875rem;
    background-color: transparent;
    border: none;
}

.modal{
background-color:white;
border-radius: 5px;
max-width: 50rem;
margin: 0 auto;
padding: 1.2rem;
position: relative;
}

.privacyContainer{
  display: flex;
  justify-content: space-around;
}

.privacyLeft{
  width: 45%;
}

.privacyRight{
  width: 45%;
}

//mobile devices
// @media (min-width: 320px) and (max-width: 480px) {
//   .footer-pop-logo {
//     background: black;
//   }



//   .footer-pop-left {
//     width: 100%;
//     p {
//       bottom: 20px;
//     }
//     h1 {
//       padding-bottom: 10px;
//       font-weight: bold;
//     }
//   }

//   .footer-pop-right {
//     width: 100%;
//     p {
//       bottom: 20px;
//     }

//     h1 {
//       padding-bottom: 10px;
//       font-weight: bold;
//     }
//   }
// }


.policy-header{
  font-family: "daimler_cs_regular";
  font-size: 2.25rem ;
  line-height:1.875rem;
  color: #1698cb;
  padding-bottom: 0.3125rem;
  

}

.policy-subheader{
  font-size: 1rem ;
  font-family: "daimler_cs_regular";
  line-height:1.875rem;
  color: #000;
  padding-bottom:0.625rem;
  a{
    font-weight: bold;
  }
}

.policy-level-header{
  font-size: 1.5rem;
  font-family: "daimler_cs_regular";
  padding-bottom:0.3125rem;
  line-height:1.875rem;
  color:#2f2f2f;
}

.terms-header{
  font-size: 2rem ;
  font-family: "daimler_cs_regular";
  line-height: 1.875rem;
  color: #1698cb;
  padding-bottom:0.625rem;
}

.footer-links{
  cursor: pointer;
  text-decoration: underline;
}


.statusContainer{
  display: flex;
  width: 100%;
}

input#status {
  zoom: 1.5;
  vertical-align: middle;
}

#status{
  padding: 0.5rem;
  margin: 0.5rem;
}

.checkBoxContainer{
  width: 50%;
}

.statusBtn{
  text-align: right;
  width: 50%;
}


.warningMessage{
  background: red;
  font-family: "daimler_cs_regular";
  font-weight: bold;
  line-height: 1.5;
  padding: 10px;
  margin: auto;
}


// search

.truck-button{
  width: 100%;
  height: 34px;
  // padding: 0.375rem 0.75rem;
  font-size: 0.875rem !important;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
.initial-truck{
  margin-right: 1rem;
}
.menu-search-truck{
  margin-top: 0.2rem;
  width: 100%;
  border: 1px solid #ebebeb;
  height: 34px;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem !important;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.vehilefilter-truck{
  padding-left: 10px;
  padding-top: 0px;
  width: 25rem;
 height:132px;
 cursor: pointer;
 overflow: hidden;
 overflow-y: scroll;
  color: #555;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem !important;
}

// custom map------------------------------------

#map{
  height:28.4vh;
  border-radius: 1rem;
}

@media (min-width: 1025px)  {
  
  .d-flex-responsive{
    display:flex;
    }
  
}


@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) { 
 .radial-heading{
  width: 45%;
 }
  .chart-zoom{
    zoom:0.5;
  }
  .each-width{
    height: 17rem;
  }

  .each-width1{
    height: 17rem;
  }

  #map{
    height: 26.4vh;
  }
  .createTrip{
    padding: 10px;
    border-radius: 4px;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
  
    right: 0px;
    top: 15px;
  }

}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .radial-heading{
     width: 33%;
   }
 .each-width{
   height: 14rem;
 }
 .each-width1{
   height: 14rem;
 }
 #map{
   height: 28.7vh;
 }

  .chart-zoom{
    zoom:0.46;
  }

  .createTrip{
    padding: 10px;
    border-radius: 4px;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    right: 0px;
    top: 15px;
  }
  
}


//logout Modal

.logoutModal{
  position: fixed;
  top: 0rem;
  left: 0rem;
  width: 100%;
  overflow: auto;
  background: rgba(0, 0, 0, 0.9);
  padding: 0px 10px;
  bottom: 0;
  z-index: 9;
}

.logoutModal .logoutContainer{
  text-align: center;
  background-color:#eeeeee;
  position: relative;
  top: 28%;
  width: 100%;
  max-width: 500px;
  margin: 30px auto;
  padding: 30px 50px;
  left: 0;
  right: 0;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  border-radius: 6px;

  .buttonContainer{
    display: flex
  }
}
//create trip 

.createTrip{
  padding: 10px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  position: absolute;
  right: 0px;
  top: 15px;
}
.createTripModal{
  position: fixed;
  top: 0rem;
  left: 0rem;
  width: 100%;
  overflow: auto;
  background: rgba(0, 0, 0, 0.6);
  padding: 0px 10px;
  bottom: 0;
  z-index: 9;

   .gmnoprint{
   display: none;
 }

  
}
.tripHeading{
  position: relative;
  @include flexbox();
  background-color: #eeeeee;
  @include justify-content(space-between);
  @include align-items(center);
  border: 0;
  
  h3 {
    font-size: 24px;
    line-height: 22px;
    color: #000;
    margin: 0 15px;
    padding: 15px 0px;
    margin-top: 10px;
    font-family: 'daimler_cs_regular';
  }
}
.createTripModal .createTripContainer{
  background-color:#eeeeee;
  width: 100%;
  max-width: 730px;
  margin: 30px auto;
  padding: 30px 50px;
  left: 0;
  right: 0;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  border-radius: 6px;
}
.relative{
  position: relative;
}
.createTripContainer .formContainer .errorMsg{
  top:45px;
  font-size: 0.7rem;
}
.createTripContainer .formContainer .pickers .errorMsg{
  top:50px;
  width: 50%;
}
.createTripContainer .formContainer .pickers .timePicker .errorMsg{
  width: 100%;
}
.createTripContainer .formContainer .errorMsgNew .errorMsg{
  top: 15px;
  left: 160px;
}
.createTripContainer .formContainer .form-group{
  background:#fff;
  font-family: 'daimler_cs_regular';
  border: 1px solid #ccc;
  padding: 0px;
  width: 100%;
  outline: 0;
  font-size: 16px;
  box-sizing: border-box;
  margin: 10px 0 20px;

  .select{
    height: auto;
    padding-bottom: 0px;
    padding: 10px 15px;
    font-weight: normal;
    border: none;
    width: 100%;
   // height: 34px;
   // padding: 0.375rem 0.75rem;
    font-size: 0.875rem !important;
    color: #555;
    font-family: "daimler_cs_regular";
    background-color: #fff;
    background-image: none;
    border-radius: 4px;
    font-weight: 600;
   // box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);

    .selectTruck1{
      float: right;
      font-weight: normal;
    }

    .autoWidth{
      margin-left: -1rem;
    width: 100%;
    margin-top: 0.5rem;
    }

    .truckList{
      padding: 2px;
    }

    .selectTruck{
      cursor: pointer;
      font-weight: normal;
      font-size: 0.875rem !important;
      color: #555;
      font-family: "daimler_cs_regular";

    }
  }
    
  select{
    height: auto;
    padding: 13px 15px;
    font-weight: normal;
    border: none
  }
  input[type="text"]{
    height: auto;
    padding: 10px 15px;
    border: none;
    font-family: 'daimler_cs_regular';
    font-size: 0.875rem;
    line-height: inherit;
  }
}
.formContainer{
  display: flex;
  position: relative;
  padding: 0px 2%;
  justify-content: space-around;
  .form-group-new{
    display: flex;
    font-family: 'daimler_cs_regular';
    padding: 0px;
    width: 100%;
    align-items: center;
    outline: 0;
    font-size: 16px;
    box-sizing: border-box;
    margin: 10px 0 18px;
    input[type="text"]{
      height: auto;
      padding: 10px 15px;
      width: 20%;
      font-family: 'daimler_cs_regular';
      border: none;
      border: 1px solid #ccc;
      font-size: 0.875rem;
      line-height: inherit;
    }
    label{
      width: 32%;
      font-family: 'daimler_cs_regular';
      font-size: 0.875rem;
    }
  }
  .form-group{
    background: #fff;
    font-family: 'daimler_cs_regular';
    border: 1px solid #dfdcdc;
    border-radius: 1%;
    padding: 15px;
    width: 100%;
    outline: 0;
    font-size: 16px;
    box-sizing: border-box;
    margin: 0 0 15px;
    input[type="text"] {
      border: none;
      font-family: 'daimler_cs_regular';
      padding-left: 5px;
      width: 100%;
  }
  }
}
.submitButton{
  border: none;
  cursor: pointer;
  padding: 10px 25px;
  color: white;
  background: #00677f;
}
.submitButtonDisabled{
  cursor: not-allowed;
  border: none;
  padding: 5px 12px;
  color: white;
  background: #00677f;
}
.leftWrapper {
  width: 49%;
}
.rightWrapper{
  width: 49%;
  margin-left: 2%;
}
.driverText,.phoneText{
  border: none;
}
.cancelTrip{
  display: flex;
  position: absolute;
  cursor: pointer;
  outline: none;
  border-radius: 50%;
  background: #fff;
  border: none;
  top: -10px;
  justify-content: center;
  right: -30px;
  /* font-size: 17px; */
  padding: 1.5px 7px;
  width: 30px;
  height: 30px;

  span{
    margin: auto;
  }
}
.location{
  padding: 0.5% 2%;
}
.submitContainer{
  display: flex;
  justify-content: space-between;
  padding: 0px 2% 2%;
  .tripContainer{
    input{
      margin: 0.3rem 0.3rem 0.3rem 0 !important; 
    }
  }
}
.tripButton{
  cursor: pointer;
  padding: 5px;
  background: #0c96e8;
  color: #fff;
  border: 0;
  transition: all 0.3 ease;
  font-family: 'Roboto'
}
.destinationContainer{
  height: 47vh;
    left: 12px;
    top: 56px;
    width: 84vh;
  position:absolute;
  z-Index:999
}
.mapContainer{
     /* height: 81%; */
     top: 44px;
     width: 84vh;
     height: 47vh;
     left: 1px;
     /* width: 96%; */
     /* left: 2%; */
     position: absolute;
     z-Index: 999;
 }
 .errorMsg{
  position: absolute;
  z-index: 999;
  color:red;
 }
 .pickers{
   display: flex;
   .react-datepicker-wrapper{
    width: 93%;
  }
  .form-group.datePicker{
    width: 65%;
    padding: 0px 8px 0px 15px;
    display: flex;
    align-items: center;
    .react-datepicker-wrapper{
      input[type="text"] {
        padding: 10px 10px;
    }
    }
  }
  .timePicker{
    margin-left: 2%;
    padding: 0px 5px;
    display: flex;
    svg{
      display: none;
    }
  }
  .react-datepicker__input-container{
        display: block;
        width: 100%;
  }
  input[type="text"] {
    border: none;
    padding-left: 5px;
    width: 100%;
}
 }
 
 .ant-time-picker-panel.ant-time-picker-panel-narrow {
  margin-left: -3px;
  margin-top: 47px;
 }
 .ant-time-picker-panel-inner{
   top: 47px;
   left: 0px !important;
 }
 .mainHeader{
   display: flex;

 }

 .header-height{
   height: 12rem;
 }
 .inputContainer{
   position: absolute;
   z-index: 999;
   left: 30px;
   width: 40%;
   top: 30px;
   input[type="text"] {
    border: none;
    padding: 10px;
    width: 100%;
}
 }


//  .pickers{
//   .react-datepicker__input-container{
//   input[type="text"]{
//   padding:10px 10px;
//   }}
//   }

@media screen and (max-width: 768px){
  .mapContainer{
    width: 59vh !important;
    height: 33vh !important;
  }

  .destinationContainer{
    width: 59vh;
    height: 33vh;
    left: 13px;
  }
}

@media screen and (max-width: 1024px){
  .mapContainer{
    width: 79vh;
    height: 44vh;
  }

  .destinationContainer{
    width: 79vh;
    height: 44vh;
    left: 13px;
  }
}


#pac-container {
  padding-bottom: 12px;
  margin-right: 12px;
}

.pac-card {
  margin: 10px 10px 0 0;
  border-radius: 2px 0 0 2px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  outline: none;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  background-color: #fff;
  font-family: Roboto;
}

#pac-container {
  padding-bottom: 12px;
  margin-right: 12px;
}

.pac-controls {
  display: inline-block;
  padding: 5px 11px;
}

.pac-controls label {
  font-family: Roboto;
  font-size: 13px;
  font-weight: 300;
}

#pac-input {
  background-color: #fff;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 300;
  margin-left: 12px;
  padding: 0 11px 0 13px;
  text-overflow: ellipsis;
  width: 400px;
}

#pac-input:focus {
  border-color: #4d90fe;
}

#title {
  color: #fff;
  background-color: #4d90fe;
  font-size: 25px;
  font-weight: 500;
  padding: 6px 12px;
}

.custom-map{
  display :flex;
}

.playData{
  width:20%;
}

.playNotification{
  position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  width: 30%;
  background: #00677f;
  padding: 0.7rem;
  h1{
    text-align: center;
    color : rgb(45, 187, 235);
    text-decoration: underline;
    font-size: 1.8rem;
    padding-bottom: 2rem;
  }
  div{
    color: white;
   // padding: 1rem;
  }
}

.play-section{
position: absolute;
top: 20%;
}

.p-1{
  padding: 1rem;
}

.custom-map {
  .gm-style .gm-style-iw-c{
    transform: translate(-47%,-141%);
  
  }
}

.gm-style .gm-style-iw-c{
  overflow-y: auto;
}

.tooltip {
  position: relative;
  display: inline-block;
 
}

.tooltip .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.tooltip .tooltiptext {
 visibility: visible;
 width: 100px;
 height:auto;
 background-color:white;
 border: solid black 1px;
 color: #fff;
 padding: 0.5rem;
  text-align: center;
  border-radius: 6px;
// padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -75px;
  opacity: 1;
 transition: opacity 0.3s;
}

.tooltip .tooltiptext::before {
  border-width: 6px;
  left: 49%;
  content: "";
  position: absolute;
  text-align: center;
  top: 100%;
  margin-left: 14px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  text-align: center;
  top: 100%;
  left: 50%;
  margin-left: 15px;
  border-width: 4px;
  border-style: solid;
  border-color: white transparent transparent transparent;
}

.emailImage{

// padding-right:0.3rem;
zoom: 0.9;
//padding-right: 0.1rem;

padding-bottom: 0.5rem;
padding-top: 0.8rem;

}

.email-wrapper{
 
        display: block;
margin-left: 1.3rem;
  width: 40px;
height: 40px;

  background: darkred;
// padding: 0.4rem;
  border-radius: 50%;
//  // overflow: hidden;
  
}


.endTrip{
  padding: 5px 15px;
  margin-left: 10px;
}

.endTripContainer{
  position: fixed;
  display: flex;
  align-items: center;
  top: 0rem;
  left: 0rem;
  width: 100%;
  overflow: auto;
  background: rgba(0, 0, 0, 0.6);
  padding: 0px 10px;
  bottom: 0;
  z-index: 9;
}

.endTripContainer .endTripModal{
  background-color:#eeeeee;
  width: 100%;
  max-width: 400px;
  margin: 30px auto;
  padding: 30px 50px;
  left: 0;
  right: 0;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  border-radius: 6px;

  .rghtTick{
    display: flex;
    justify-content: center;
  }

  .buttonContainer{
    display: flex;
    justify-content: center;

    .okayButton{
      padding: 5px;
    }

    .cancelButton{
      padding: 5px;
    }
  }

  .content{
    text-align: center
  }
}

.btn.cancel-btn{
  color: #fff;
  background-color: gray;
} 

.tableWrapperAnalysys table tbody tr td{
  font-size: 0.8rem;
  padding: 0.1rem;
}

.tableWrapperAnalysys table thead tr th{
  padding: 0.1rem;
  font-size: 0.8rem;
}

.tableWrapperAnalysys table tbody tr td:last-child{
  white-space: normal;
  overflow: unset
}
.tableWrapperAnalysys{
.playback {
  border-radius: 50%;
  background-color: #337ab7;
  padding: rem(10);
  font-size: rem(14.667);
  color: #333;
  cursor: pointer;
  border: none;
  outline: none;
}
}

#rating > .ant-rate{
  width:9rem;
}

.tripCheck{
  display: flex
}

#trip-wrapper{
  .gmnoprint{
    display: none;
  }
  .gm-style .gm-style-iw-t::after {
    background: linear-gradient(45deg,rgba(255,255,255,1) 50%,rgba(255,255,255,0) 51%,rgba(255,255,255,0) 100%);
    box-shadow: -2px 2px 2px 0 rgba(178,178,178,.4);
    content: "";
    height: 15px;
    left: 0;
    position: absolute;
    top: 0;
    transform: translate(-50%,-50%) rotate(-45deg);
    width: 15px;
   }

  .gm-style-iw .gm-style-iw-c{
    overflow-y: auto;
   }
}
.d-flex-map{
  display: flex;

}
@media (min-width: 769px) {
  .adl-fuel-width{
    width: 50%;
  }

  .custom-width1{
    width: 45%;
    word-break: break-all;
  
  }
  .custom-width2{
    width:45%
  }
.maps-popup{
  .myfleetsprite {
  //  background-image: url(../../assets/images/myfleetspritealter_updated-v1.png);
    &.one {
        width: 56px;
        height: 24px;
        background-position: -5px -2px;
        display: inline-block;
    }
    &.two {
        width: 34px;
        height: 27px;
        background-position: -5px -28px;
        padding-right: 2.2rem;
        display: inline-block;
        background-repeat: no-repeat;
    }
    &.three {
        width: 56px;
        height: 28px;
        background-position: -6px -50px;
        display: inline-block;
    }
    &.four {
        width: 33px;
        padding-right: 2.2rem;
        height: 28px;
        background-position: -6px -74px;
        display: inline-block;
        background-repeat: no-repeat;
    }
    &.five {
        width: 34px;
        height: 30px;
        background-position: -11px -100px;
        display: inline-block;
    }
    &.six {
        width: 37px;
        height: 28px;
        background-position: -9px -127px;
        display: inline-block;
    }
    &.seven {
        width: 37px;
        height: 28px;
        background-position: -9px -156px;
        display: inline-block;
    }
}
}



.max-data1{
 // text-align: center;
 // display: flex;
  align-items: center;
}

 .max-data2{  
    align-items: center;
}

.lastdata{
  justify-content: space-between
 li{
   width: 71%;

 }
}
.firstdata{
  padding-bottom: 2px;
}
.regid{
  padding-bottom: 0.8rem;
font-weight: bold;
}
}


@media (max-width: 768px) {
  .d-flex-map{
    display: block;
  }
  .adl-fuel-width{
    width: 64%;
  }

  .maps-popup{
    .max-data1 {
      span{
      position: relative;
      top:-7px;
    }
  }

  .max-data2{
    span{
      position: relative;
      top:-7px;
    }
  }
    .myfleetsprite {
    //  background-image: url(../../assets/images/myfleetspritealter_updated-v1.png);
      &.one {
          width: 56px;
          height: 24px;
          background-position: -5px -2px;
          display: inline-block;
          background-repeat: no-repeat;
      }
      &.two {
          width: 32px;
          height: 27px;
          background-position: -5px -28px;
          display: inline-block;
      }
      &.three {
          width: 56px;
          height: 28px;
          background-position: -6px -50px;
          display: inline-block;
      }
      &.four {
          width: 30px;
          height: 28px;
          background-position: -6px -74px;
          display: inline-block;
          background-repeat: no-repeat;
      }
      &.five {
          width: 27px;
          height: 30px;
          background-position: -11px -100px;
          display: inline-block;
      }
      &.six {
          width: 37px;
          height: 28px;
          background-position: -9px -127px;
          display: inline-block;
      }
      &.seven {
          width: 37px;
          height: 28px;
          background-position: -9px -156px;
          display: inline-block;
      }
  }
  }

  .regid{
    padding-bottom: 0.8rem;
  font-weight: bold;
  }
}

.trip-analysis{
  .reportFilters{
    div:first-child{
      padding: 0px;
    }
    .select-vehc{
      padding: 0px !important;
      .selectLabel{
        margin-left: 0.5rem;
      }
    }

    .search-fil{
      padding: 0px !important;
    }

    .create-btn1{
      padding: 0px !important;
     
     
      
    }

    .shr-btn{
      padding: 0px !important;
    }

    .end-trp{
      padding: 0px;
      background-color: darkred;
      .btn.primary-btn{
        background-color: darkred;
        border:none;
      }
    }
  }
}

@media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation:landscape) {
 .custom-width1{
   width: 80%;
 }

 .maps-popup .myfleetsprite.five {
   width: 33px;
 }
}
.initial-style{
  display: none;
}

.checkBoxed{
  width: 2%;
}

.tripId0{
  width: 4%;
}

.fromCity1{
  width: 6%;
}

.toCity2{
  width: 6%;
}

.scheduledStartTime3{
  width: 9%;
}

.scheduledEndTime4{
  width: 9%;
}

.registrationId5{
  width: 9%;
}
.driverFullName6{
  width: 10%;
}
.averageSpeed7{
  width: 0%;
}
.plannedStops8{
  width: 0%;
}
.actualNoStops9{
  width: 3%;
}

.distance10{
  width: 6%;
}

.score11{
  
}

.playback12{

}
.arrivalStatus13{

}

.eta14{

}

@media (min-width: 1025px) and (max-width: 1280px) {  
  .tableWrapperAnalysys table tbody tr td{ 
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .tableWrapperAnalysys table tbody tr td:last-child{
    white-space: normal;
    overflow: unset
  }
 }

 @media (min-width: 1281px) {
  .tableWrapperAnalysys table tbody tr td{ 
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .tableWrapperAnalysys table tbody tr td:last-child{
    white-space: normal;
    overflow: unset
  }
 }

 .tripPlayback{
   padding: 0.5rem !important;
   zoom: 0.8
 }

 .loadMorePage{
   padding-top: 0.5rem;
 }

 .tableWrapperAnalysys table thead tr th:last-child{
   min-width: 48px;
 }


 //Alert Preferences
 .ChkBox .stoppageEmail{
   left: -17px;
 }

 .ChkBox .fuelDropEmail{
   left: 5px;
 }

 .ChkBox .geofenceInEmail{
   left: -2px;
 }

 .ChkBox .geofenceOutEmail{
   left: -7px;
 }

 .ChkBox .neutralDrivingEmail{
   left: -11px;
 }
 .ChkBox .dtcEmail{
   left: 21px;
 }

 .ChkBox .adblueAlert{
   left: -3px;
 }

 .pdfDownload{
   float:right;
   line-height: 2.4;
   width: 10rem;
    border: 0.4px solid grey;
    border-radius: 24px;
    cursor: pointer;
 }

 .pdf-icon-settings{
   float: left;
   padding: 0.2rem;
 }

 .pdf-text{
  padding: 0.2rem;
 }

 #submit-btn-can{
 // margin-top: 1rem;
  background-color: #2881cc;
  width: 100%;
  border-radius: 2rem;
  position: relative;
  bottom: 1rem;
 }

 .can-signout{
   padding: 1rem;
   text-align: end;
   padding-top: 3.5rem;
 }

 
 .select{
  height: auto;
  padding-bottom: 0px !important;
  padding: 10px 15px;
  font-weight: normal;
  border: none;
  // width: 100%;
 // height: 34px;
 // padding: 0.375rem 0.75rem;
  font-size: 0.875rem !important;
  color: #555;
  font-family: "daimler_cs_regular";
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  font-weight: 600;
  //box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);

  .selectTruck1{
    
    font-weight: normal;
  }

  .autoWidth{
    margin-left: -1rem;
  // width: 100%;
  margin-top: 0.5rem;
  }

  .truckList{
    padding: 2px;
  }

  .selectTruck{
    cursor: pointer;
    font-weight: normal;
    position: relative;
    top : 10px;
    left: 1rem;
    font-size: 16px !important;
    color: #555;
    padding-right: 1rem;
    font-family: "daimler_cs_regular";

  }
}
  
select{
  height: auto;
  padding: 13px 15px;
  font-weight: normal;
  border: none
}
input[type="text"]{
  height: auto;
  padding: 10px 15px;
  border: none;
  font-family: 'daimler_cs_regular';
  font-size: 0.875rem;
  line-height: inherit;
}


.pickers{
  display: flex;
  .react-datepicker-wrapper{
   width: 93%;
 }
 .form-group .datePicker{
   width: 65%;
   padding: 0px 8px 0px 15px;
   display: flex;
   align-items: center;
   .react-datepicker-wrapper{
     input[type="text"] {
       padding: 10px 10px;
   }
   }
 }
 .timePicker{
   margin-left: 2%;
   padding: 0px 5px;
   display: flex;
   svg{
     display: none;
   }
 }
 .react-datepicker__input-container{
       display: block;
       width: 100%;
 }
 input[type="text"] {
   border: none;
   padding-left: 5px;
   width: 100%;
}
}

.ant-time-picker-panel.ant-time-picker-panel-narrow {
 margin-left: -3px;
 margin-top: 47px;
}
.ant-time-picker-panel-inner{
  top: 47px;
  left: 0px !important;
}

.btnDisabled{
  cursor: not-allowed
}

.btnEnabled{
  cursor: pointer;
}

.blogo{
padding: 1rem
}

.truck-logo-can{
  padding-left: 1rem;
  zoom: 0.8;
  border-left: 2px solid #9D9D9D;
  margin-top: 1.8rem;

}

.canlogo{
  padding: 1rem;
  padding-top: 3.5rem;

}

.slogo{
  zoom  : 0.8;
  padding-top: 1.5rem;
  cursor: pointer;
}

.select-date{
  height: auto;
  padding: 10px 15px;
  font-weight: normal;
  border: none;
  font-size: 0.875rem !important;
  color: #555;
  font-family: "daimler_cs_regular";
  background-color: #fff;
  background-image: none;
 
  font-weight: 600;
 
}

.btn-submit{
  margin:  23px 15px;
  background: #2881cc;
  font-family: "daimler_cs_regular";
  border: 1px solid #409fef;
  border-radius: 32px;
  height: 3rem;
  width :400px;
  color: white;
  font-size: 20px;
  width: 22rem;
    margin-left: 2.4rem;
}

.svin{
  border: 1px solid #409fef;
  border-radius: 32px;
  height: 3rem;
  cursor: pointer;
  width :400px;
  background-color: #f5f5f5;
}

.vinlabel{
padding: 0.5rem;
padding-left: 1rem;
color: #5c5c5c;
}

.can-down-arrow{
float: right;
padding:1rem;
}

.date-wrapper{
cursor: pointer;
}

.ant-input{
  background-color: #f5f5f5 !important;
  border: 1px solid #409fef !important;
  border-radius: 32px !important;
  height: 3rem !important;
  width: 400px !important;
}

.ant-calendar{
width: 400px !important;
}

.can-search{
  border: 1px solid #ebebeb !important;
}

#login-styles{
  color: #2b2b2b;
  font-weight: bold;
  font-size: 24px;
  margin-top: 4.25rem;
  margin-bottom: 1.625rem;
}

.date-label{
  padding-top: 2rem;
}
.vin-label{
  padding-top: 4rem;
}
.can-container{
  padding-left: 5rem;
}


@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) { 

  .loader-img-can {
    padding: 4rem;
    text-align: center;
}

header .navbar-header{
  width: 80%;
}
}


@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .loader-img-can {
    padding: 4rem;
    text-align: center;
}
}

.btn-yes{
background-color: #2881cc;
color: white;
border-radius: 6px;
border: 1px solid #2881cc;
width: 5rem;
height: 2rem;
font-family: "daimler_cs_regular";
margin: 0.5rem;
cursor: pointer;
}

.btn-no{
  cursor: pointer;
  margin: 0.5rem;
  height: 2rem;
  width: 5rem;
  background-color: #9d9d9d;
  border: 1px solid #9d9d9d;
  color: white;
  border-radius: 6px;
  font-family: "daimler_cs_regular";
}

.modal-signout{
  padding: 1rem;
  padding-bottom: 0.5rem;
  zoom: 0.8;
}

.modal-label{
  padding-bottom: 1rem;
  font-family: "daimler_cs_regular";
  padding-top: 0.5rem;
}

#togglePassword{
  left: 8.5rem;
  bottom: 4.8rem;
    position: relative;
    zoom: 0.8;
    height: 29px;
    width: 24px;
}

#text {
  visibility:hidden;
  font-size: 0.75rem;
 // font-family: "daimler_cs_regular";
}

.errMsg1{
  color: white;
  text-align: left;
    padding-top: 2px;
    
    margin: 0 !important;

}

.visible{
  visibility: visible;
}

.notVisible{
  visibility: hidden;
}

.zero-bottom{
  margin-bottom: 0 !important;
}

.matrix-label{
  color: #2881cc;;
  padding-top: 1rem;
  cursor: pointer;
  font-family: "daimler_cs_regular";
}

.uname-label{
  padding-top: 1.3rem;
  width :60%;
  color:#5c5c5c;
  font-family: "daimler_cs_regular";
}

.signInButton{
  appearance: none;
  background-color: #000000;
  border: 2px solid #1A1A1A;
  border-radius: 15px;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  min-height: 60px;
  width: 100%;
}

.signOutBtn{
  cursor:  pointer !important;
}